//
// authentication.scss
//

// authentication home icon
@include media-breakpoint-up(md) {
  .auth-row {
    margin: 0px 155px;
  }
}

.account-pages {
  background: url("../../../images/Background-min.png") no-repeat center center
    fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.auth-form-card .btn-block {
  padding-top: 11px;
  padding-bottom: 11px;
}

.features-left {
  ul.features-list {
    padding-left: 0;
    list-style-position: outside;

    li::marker {
      font-size: 1.3rem;
      color: $text-muted;
    }
  }

  .feature {
    margin-top: 2.5rem;

    h4 {
      font-size: 18px;
    }
  }

  .auth-footer > small {
    font-size: 12px;
  }
}
