.grid-wrapper {
  position: absolute;
  width: 100%;
  height: 30%;
  left: 0;
  bottom: 0px;
  perspective: 200px;
}

.grid {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin-top: 0px;
  transform: rotateX(45deg);
}

.line {
  position: absolute;
  background: rgba(87, 193, 232, 0.05);
  -webkit-backface-visibility: hidden;
}

.vertical .line {
  top: 0;
  left: 0;
  bottom: 0;
  width: 5px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.1),
    rgba(87, 193, 232, 0.1)
  );
  &:nth-child(1) {
    left: 10%;
  }
  &:nth-child(2) {
    left: 20%;
  }
  &:nth-child(3) {
    left: 30%;
  }
  &:nth-child(4) {
    left: 40%;
  }
  &:nth-child(5) {
    left: 50%;
  }
  &:nth-child(6) {
    left: 60%;
  }
  &:nth-child(7) {
    left: 70%;
  }
  &:nth-child(8) {
    left: 80%;
  }
  &:nth-child(9) {
    left: 90%;
  }
  &:nth-child(10) {
    left: 100%;
    margin-left: -1px;
  }
}

.horizontal .line {
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  z-index: 2;

  &:nth-child(1) {
    top: 10%;
    opacity: 0.2;
  }
  &:nth-child(2) {
    top: 20%;
    opacity: 0.3;
  }
  &:nth-child(3) {
    top: 30%;
    opacity: 0.4;
  }
  &:nth-child(4) {
    top: 40%;
    opacity: 0.5;
  }
  &:nth-child(5) {
    top: 50%;
    opacity: 0.6;
  }
  &:nth-child(6) {
    top: 60%;
    opacity: 0.7;
  }
  &:nth-child(7) {
    top: 70%;
    opacity: 0.8;
  }
  &:nth-child(8) {
    top: 80%;
    opacity: 0.9;
  }
  &:nth-child(9) {
    top: 90%;
    opacity: 0.95;
  }
  &:nth-child(10) {
    top: 100%;
    margin-top: -1px;
    opacity: 1;
  }
  &:nth-child(11) {
    opacity: 0.05;
  }
}

.dot {
  position: absolute;
  background: rgba(87, 193, 232, 0.2);
  width: 5px;
  height: 5px;
  top: 100%;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(87, 193, 232, 0.4);
  -webkit-backface-visibility: hidden;
  &:nth-child(1) {
    left: 10%;
    animation: moveDot 2.5s infinite;
  }
  &:nth-child(2) {
    left: 20%;
    animation: moveDot 2.5s infinite;
  }
  &:nth-child(3) {
    left: 30%;
    animation: moveDot 2s infinite;
  }
  &:nth-child(4) {
    left: 50%;
    animation: moveDot 6s infinite;
  }
  &:nth-child(5) {
    left: 60%;
    animation: moveDot 6s infinite;
  }
  &:nth-child(6) {
    left: 80%;
    animation: moveDot 2s infinite;
  }
  &:nth-child(7) {
    left: 90%;
    animation: moveDot 2.5s infinite;
  }
  &:nth-child(8) {
    left: 100%;
    animation: moveDot 2.5s infinite;
  }
}

@keyframes moveDot {
  0% {
    top: 100%;
    opacity: 1;
  }
  100% {
    top: 0%;
    opacity: 0;
  }
}
